import logo from './logo.svg';
import './App.css';
import Publicroutes from './routes/Publicroutes';

function App() {
  return (
    <div className="App">
      <Publicroutes></Publicroutes>
    </div>
  );
}

export default App;
